@font-face {
  font-family: 'Rubik';
  src: local('Rubik'), url(assets/fonts/Rubik-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'QanelasSoft';
  src: local('QanelasSoft'), url(assets/fonts/QanelasSoft-ExtraBold.otf) format('opentype');
}

@media (width >= 800px) {
  .main-banner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 20px;
    padding: 49px;
  }

  .main-banner {
    flex: 0 1 auto;
    padding: 124px 168px;

    background: #F3F8FB;
    border-radius: 20px;
  }

  .main-banner__title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 0px;
  }

  .main-banner__title {
    font-family: QanelasSoft;
    font-style: normal;
    font-size: 24px;
    line-height: 32px;

    text-align: center;

    letter-spacing: 0.000872727px;

    color: rgba(32, 32, 32, 0.9);

    margin-bottom: 12px;
  }

  .main-banner__subtitle {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;

    text-align: center;

    color: rgba(32, 32, 32, 0.9);

    margin-bottom: 25px;
  }

  .icon-main {
    margin-bottom: 24px;
  }

  .icon-main svg {
    width: 64px;
    height: 64px;
  }

  .icon-main > svg > g > path {
    transform: translate(12px, 12px);
  }

  .icon-info > svg > g > path {
    transform: translate(25px, 15px);
  }

  .step-icon svg {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    margin-top: 2px;
  }

  .step-icon__number {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-variation-settings: 'wght' 500;
    font-size: 18px;
    line-height: 15px;
    fill: #FFFFFF;
  }

  .project-preview__container {
    color: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 51px 67px rgba(8, 78, 92, 0.03), 0px 18.6158px 24.4561px rgba(8, 78, 92, 0.03), 0px 9.03764px 11.873px rgba(8, 78, 92, 0.04), 0px 4.43042px 5.82036px rgba(8, 78, 92, 0.03), 0px 1.7518px 2.30138px rgba(8, 78, 92, 0.02);
    border-radius: 20px;
    width: 197px;
    height: 176px;
  }

  .project-preview__thumbnail-container {
    background-image: url(assets/images/project-preview.png);
    width: 197px;
    height: 124px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .project-preview__user {
    position: relative;
    width: 32px;
    height: 32px;
    left: 173px;
    top: -132px;
  }

  .project-preview__project-name-container {
    position: relative;
    width: 149px;
    height: 32px;
    left: 8px;
    top: -24px;

    background-color: rgba(255, 255, 255, 0.8);
    mix-blend-mode: normal;
    border-radius: 4px;
  }

  .project-preview__project-name {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba(32, 32, 32, 0.9);

    position: relative;
    height: 24px;
    bottom: -4px;
  }

  .project-preview__more {
    position: relative;
    left: 166px;
    bottom: 52px;
  }

  .project-accepted-tutorial {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .project-accepted-tutorial__step-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 238px;

    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;

    color: rgba(39, 39, 39, 0.8);
  }

  .project-accepted-tutorial__step-container:nth-child(1) {
    margin-right: 30px;
  }

  .project-accepted-tutorial__step-description {
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 16px;
  }

  .project-accepted-tutorial__app-open {
    width: 197px;
    height: 176px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-variation-settings: 'wght' 500;
    font-size: 14px;
    line-height: 20px;

    text-align: center;

    color: rgba(32, 32, 32, 0.9);
  }

  .project-accepted-tutorial__app-open__app-name {
    padding: 0px 40px 0px 40px;
    margin-top: 12px;
  }

  .unblock-user-tutorial__step-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 278px;

    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;

    color: rgba(39, 39, 39, 0.8);
  }

  .unblock-user-tutorial__step-description {
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 16px;
  }

  .unblock-user-preview__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background-color: white;
    color: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 51px 67px rgba(8, 78, 92, 0.03), 0px 18.6158px 24.4561px rgba(8, 78, 92, 0.03), 0px 9.03764px 11.873px rgba(8, 78, 92, 0.04), 0px 4.43042px 5.82036px rgba(8, 78, 92, 0.03), 0px 1.7518px 2.30138px rgba(8, 78, 92, 0.02);
    border-radius: 20px;
    width: 232px;
    height: 150px;
  }

  .unblock-user-preview__parental-control {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-variation-settings: 'wght' 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba(32, 32, 32, 0.9);

    position: relative;
    height: 24px;
    padding-top: 8px;
  }

  .unblock-user-preview__unblock {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-variation-settings: 'wght' 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #7E63B6;

    position: relative;
    height: 24px;
    padding-top: 8px;
  }

  .unblock-user-preview__greyed-out-option {
    width: 119px;
    height: 24px;

    background: #F5F5F5;
    mix-blend-mode: normal;
    border-radius: 4px;

    margin-top: 9px;
  }

  .button-main {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 256px;
    height: 56px;

    background: #7E63B6;
    border-radius: 8px;

    color: white;

    cursor: pointer;

    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-variation-settings: 'wght' 500;
    font-size: 20px;
    line-height: 24px;

    margin-top: 10px;
  }

  .button-main:hover {
    background: #9882C4;
  }

  .button-main:active {
    background: #6C4EA6;
    color: rgba(255, 255, 255, 0.6);
  }
}

@media (width < 800px) {
  .main-banner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;

    border-radius: 20px;
    padding: 16px;
  }

  .main-banner {
    flex: 0 1 auto;
    padding: 88px 44px;

    background: #F3F8FB;
    border-radius: 20px;
  }

  .main-banner__title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 0px;
  }

  .main-banner__title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-variation-settings: 'wght' 500;
    font-size: 20px;
    line-height: 32px;

    text-align: center;

    letter-spacing: 0.000872727px;

    color: rgba(32, 32, 32, 0.9);

    margin-bottom: 6px;
  }

  .main-banner__subtitle {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    text-align: center;

    color: rgba(32, 32, 32, 0.9);

    margin-bottom: 15px;
  }

  .icon-main {
    margin-bottom: 12px;
  }

  .icon-main > svg {
    width: 40px;
    height: 40px;
  }

  .icon-main > svg > g > path {
    transform: translate(12px, 12px);
  }

  .icon-info > svg > g > path {
    transform: translate(25px, 15px);
  }

  .step-icon svg {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    margin-top: 2px;
  }

  .step-icon__number {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-variation-settings: 'wght' 500;
    font-size: 18px;
    line-height: 15px;
    fill: #FFFFFF;
  }

  .project-preview__container {
    color: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 51px 67px rgba(8, 78, 92, 0.03), 0px 18.6158px 24.4561px rgba(8, 78, 92, 0.03), 0px 9.03764px 11.873px rgba(8, 78, 92, 0.04), 0px 4.43042px 5.82036px rgba(8, 78, 92, 0.03), 0px 1.7518px 2.30138px rgba(8, 78, 92, 0.02);
    border-radius: 20px;
    width: 197px;
    height: 176px;
  }

  .project-preview__thumbnail-container {
    background-image: url(assets/images/project-preview.png);
    width: 197px;
    height: 124px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .project-preview__user {
    position: relative;
    width: 32px;
    height: 32px;
    left: 173px;
    top: -132px;
  }

  .project-preview__project-name-container {
    position: relative;
    width: 149px;
    height: 32px;
    left: 8px;
    top: -24px;

    background-color: rgba(255, 255, 255, 0.8);
    mix-blend-mode: normal;
    border-radius: 4px;
  }

  .project-preview__project-name {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba(32, 32, 32, 0.9);

    position: relative;
    height: 24px;
    bottom: -4px;
  }

  .project-preview__more {
    position: relative;
    left: 166px;
    bottom: 52px;
  }

  .project-accepted-tutorial {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .project-accepted-tutorial__step-container {
    display: flex;
    flex-direction: column;
    width: 238px;
    justify-content: center;
    align-items: center;

    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    color: rgba(39, 39, 39, 0.8);
  }

  .project-accepted-tutorial__step-container:nth-child(1) {
    margin-bottom: 20px;
  }

  .project-accepted-tutorial__step-container:nth-child(2) > .project-accepted-tutorial__step-description{
    margin-bottom: 16px;
  }

  .project-accepted-tutorial__step-description {
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;
  }

  .project-accepted-tutorial__app-open {
    width: 197px;
    height: 176px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-variation-settings: 'wght' 500;
    font-size: 14px;
    line-height: 20px;

    text-align: center;

    color: rgba(32, 32, 32, 0.9);
  }

  .project-accepted-tutorial__app-open__app-name {
    padding: 0px 40px 0px 40px;
    margin-top: 12px;
  }

  .unblock-user-tutorial__step-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 238px;

    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;

    color: rgba(39, 39, 39, 0.8);
  }

  .unblock-user-tutorial__step-description {
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 0px;
    margin-bottom: 16px;
  }

  .unblock-user-tutorial__step-container:nth-child(1) {
    margin-bottom: 20px;
  }

  .unblock-user-preview__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background-color: white;
    color: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 51px 67px rgba(8, 78, 92, 0.03), 0px 18.6158px 24.4561px rgba(8, 78, 92, 0.03), 0px 9.03764px 11.873px rgba(8, 78, 92, 0.04), 0px 4.43042px 5.82036px rgba(8, 78, 92, 0.03), 0px 1.7518px 2.30138px rgba(8, 78, 92, 0.02);
    border-radius: 20px;
    width: 232px;
    height: 150px;
  }

  .unblock-user-preview__parental-control {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-variation-settings: 'wght' 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba(32, 32, 32, 0.9);

    position: relative;
    height: 24px;
    padding-top: 8px;
  }

  .unblock-user-preview__unblock {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-variation-settings: 'wght' 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #7E63B6;

    position: relative;
    height: 24px;
    padding-top: 8px;
  }

  .unblock-user-preview__greyed-out-option {
    width: 119px;
    height: 24px;

    background: #F5F5F5;
    mix-blend-mode: normal;
    border-radius: 4px;

    margin-top: 9px;
  }

  .button-main {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 256px;
    height: 56px;

    background: #7E63B6;
    border-radius: 8px;

    color: white;

    cursor: pointer;

    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-variation-settings: 'wght' 500;
    font-size: 20px;
    line-height: 24px;

    margin-top: 10px;
  }

  .button-main:active {
    background: #6C4EA6;
    color: rgba(255, 255, 255, 0.6);
  }
}
